import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Steps, Result, Button, Divider, Spin, Image, Card } from "antd";
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  InfoCircleTwoTone,
} from "@ant-design/icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBuilding,
  faRoute,
  faHome,
  faRoad,
} from "@fortawesome/free-solid-svg-icons";
import { useSubscription } from "@apollo/client";
import moment from "moment";
import "moment/locale/es";

import { subscriptionCustomerGetOrder } from "../../queries/schedulingQueries";

export const TrackingOrderScreen = () => {
  const history = useHistory();
  const { token } = useParams();

  const [tokenError, setTokenError] = useState();
  const [tokenDecoded, setTokenDecoded] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [orderNumber, setOrderNumber] = useState("");
  const [orderData, setOrderData] = useState({});
  const [icon, setIcon] = useState();

  const { data, loading } = useSubscription(subscriptionCustomerGetOrder, {
    variables: { orderNumber },
  });

  useEffect(() => {
    if (token !== undefined) {
      const verifyToken = async () => {
        const res = await fetch(process.env.REACT_APP_FUNCTION_VERIFY_TOKEN, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            token,
          }),
        });

        const result = await res.json();

        if (res.status === 200) {
          setTokenDecoded(result.statusText);
          setOrderNumber(result.statusText.orderNumber);
        } else {
          setTokenError(result.statusText);
        }
      };

      verifyToken();
    }
  }, [token]);

  useEffect(() => {
    if (!loading && data && data.routes && data.routes.length > 0) {
      let status = "";
      let startDateTime = "-";
      let endDateTime = "-";
      let startDateRoute = "-";

      switch (data.routes[0].status) {
        case "none":
        case "start":
          status = "Esperando...";
          break;
        case "started":
          status = "En camino";
          setCurrentStep(2);
          break;
        case "delivered":
          status = "Entregado";
          setCurrentStep(3);
          setIcon(<CheckCircleTwoTone twoToneColor="#52c41a" />);
          break;
        case "undelivered":
          status = "No entregado";
          setCurrentStep(3);
          setIcon(<CloseCircleTwoTone twoToneColor="red" />);
          break;
        case "partial_delivered":
          status = "Parcialmente entregado";
          setCurrentStep(3);
          setIcon(<InfoCircleTwoTone twoToneColor="orange" />);
          break;
        case "rescheduled_delivery":
          status = "Reprogramado";
          setCurrentStep(3);
          setIcon(<InfoCircleTwoTone twoToneColor="orange" />);
          break;
        default:
          status = "Esperando...";
          break;
      }

      if (data.routes[0].routeBatch.startDateRoute !== null) {
        setCurrentStep(1);
        startDateRoute = moment
          .utc(data.routes[0].routeBatch.startDateRoute)
          .format("DD/MM/YYYY HH:mm");
      }

      if (data.routes[0].startDateTime !== null) {
        setCurrentStep(2);
        startDateTime = moment
          .utc(data.routes[0].startDateTime)
          .format("DD/MM/YYYY HH:mm");
      }

      if (data.routes[0].endDateTime !== null) setCurrentStep(3);

      if (data.routes[0].endDateTime !== null)
        endDateTime = moment
          .utc(data.routes[0].endDateTime)
          .format("DD/MM/YYYY HH:mm");

      setOrderData({
        id: data.routes[0].id,
        createdAt: data.routes[0].createdAt,
        durationText: data.routes[0].durationText,
        durationValue: data.routes[0].durationValue,
        startDateRoute,
        startDateTime,
        endDateTime,
        status,
        reason: data.routes[0].reason?.description ?? "",
        evidences: data.routes[0].evidences,
      });
    }
  }, [loading, data]);

  if (loading) {
    return (
      <center
        style={{
          height: window.innerHeight,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Spin size="large" />
      </center>
    );
  }

  if (tokenError !== undefined) {
    return (
      <div
        style={{
          height: "70vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Result
          style={{
            left: "50%",
            top: "50%",
          }}
          status="500"
          title={tokenError}
          extra={[
            <Button
              key="result"
              type="primary"
              shape="round"
              size="large"
              onClick={() => history.replace("/")}
            >
              Aceptar
            </Button>,
          ]}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        height: window.innerHeight,
        padding: "20px 100px 20px 100px",
        justifyContent: "center",
        //alignItems: 'center',
      }}
    >
      <Result
        icon={<img src="https://res.cloudinary.com/srf/image/upload/v1680285850/NAVITRANS_LOGOS_COLOR_boaoxt.png"  width="300" />}
        title={`SEGUIMIENTO DEL PEDIDO`}
        subTitle={tokenDecoded.orderNumber}
        extra={
          <div>
            <br />
            <br />
            <br />
            <Steps current={currentStep} progressDot={(dot) => dot}>
              <Steps.Step
                title={
                  <FontAwesomeIcon
                    size="3x"
                    icon={faBuilding}
                    color={currentStep === 0 ? "red" : "gray"}
                  />
                }
                subTitle={
                  <small>
                    {moment(orderData.createdAt)
                      .local()
                      .format("DD/MM/YYYY HH:mm")}
                  </small>
                }
                description={
                  <div>
                    <strong>{`En bodega`}</strong>
                    <div>
                      <small>{`Preparando su pedido`}</small>
                    </div>
                  </div>
                }
              />
              <Steps.Step
                title={
                  <FontAwesomeIcon
                    size="3x"
                    icon={faRoad}
                    color={currentStep === 1 ? "red" : "gray"}
                  />
                }
                subTitle={<small>{orderData.startDateRoute}</small>}
                description={
                  <div>
                    <strong>{`En ruta`}</strong>
                    <div>
                      <small>{`Su pedido va en camino`}</small>
                    </div>
                  </div>
                }
              />
              <Steps.Step
                title={
                  <FontAwesomeIcon
                    size="3x"
                    icon={faRoute}
                    color={currentStep === 2 ? "red" : "gray"}
                  />
                }
                subTitle={<small>{orderData.startDateTime}</small>}
                description={
                  <div>
                    <strong>{`En tramo final`}</strong>
                    <div>
                      <small>{`Pedido en tramo final`}</small>
                    </div>
                  </div>
                }
              />
              <Steps.Step
                title={
                  <FontAwesomeIcon
                    size="3x"
                    icon={faHome}
                    color={currentStep === 3 ? "red" : "gray"}
                  />
                }
                subTitle={<small>{orderData.endDateTime}</small>}
                description={
                  <div>
                    <strong>{orderData.status}</strong>
                    <div>{icon}</div>
                    <div>
                      <small style={{ color: "red" }}>{orderData.reason}</small>
                    </div>
                  </div>
                }
              />
            </Steps>
            <br />
            <br />
            <div>
              {orderData.evidences && orderData.evidences.length > 0 && (
                <div>
                  <h2>Evidencias</h2>
                  <div>
                    {orderData.evidences[0].comments.length > 0 && <h4>Comentarios: {orderData.evidences[0].comments}</h4>}
                  </div>
                </div>
              )}
              <div style={{ display: "inline-flex" }}>
                {orderData.evidences &&
                  orderData.evidences.map((item, index) => {
                    return (
                      <span key={`span-${index}`} style={{ padding: "3px" }}>
                        <Card
                          key={`card-${index}`}
                          hoverable
                          style={{ width: "300", minWidth: "300" }}
                          cover={
                            <Image
                              style={{
                                borderRadius: "10px 10px 0 0",
                                objectFit: item.evidenceType === "FIRMA" ? "contain" : "cover",
                              }}
                              key={`image-${index}`}
                              width={300}
                              height={300}
                              
                              src={item.evidenceURL}
                            />
                          }
                        >
                          <Card.Meta
                            key={`cardmeta-${index}`}
                            description={item.evidenceType}
                          />
                        </Card>
                      </span>
                    );
                  })}
              </div>
            </div>
          </div>
        }
      />
    </div>
  );
};
