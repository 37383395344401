import React, { useState, useEffect } from "react";
import { Button, Table, Col,Pagination, Row, Tag } from "antd";
import { ArrowLeftOutlined, RedoOutlined, SearchOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { TextField } from "@fluentui/react/lib/TextField";
import "../stylesTMS/styles.css";
import { TMSShipmentScreen } from "./TMSShipmentScreen";
import { useLazyQuery } from "@apollo/client";
import { fetchOrders, fetchSectors, fetchDistributionCenters } from "../../../screens/TMS-Dynamics/queries/TMSqueries";
import moment from "moment";
import "moment/locale/es";
import { getColumnSearchPropsText } from "../../../../../completed-routes/ui/screens/UseCases";
import localStorageService from '../../../../../../services/localStorageService';
const CenterUser =  localStorageService.get("selectedWarehousesname");

const scheme = proto => window.location.protocol === "https:" ? `${proto}s` : proto;

export const TMSLoadScreen = (props) => {
  const history = useHistory();
  const [load, setLoads] = useState();
  const [dataFilterLoad, setDataFilterLoad] = useState([]);
  const [filter, setFilter] = useState("");
  const [selectedPageSize, setSelectedPageSize] = useState(10);
  const [selectedPageId, setSelectedPageId] = useState(1);
  const [selecteLoad, setSelecteLoad] = useState();
  const [count, setCount] = useState();
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(localStorage.getItem("range-date-tms-1"));
  const [toDate, setToDate] = useState(localStorage.getItem("range-date-tms-2"));
  const [selectedWarehousename, setSelectedWarehousename] = useState(JSON.parse(localStorageService.get("selectedWarehousesname"))?.warehousesname);
  const [getOrderRunning, dataOrderRunning] = useLazyQuery(fetchOrders, {
    fetchPolicy: "no-cache",
  });

  const [getSectors, dataSectors] = useLazyQuery(fetchSectors, {
    fetchPolicy: "no-cache",
  });

  const [getDistributionCenters, dataDistributionCenters] = useLazyQuery(fetchDistributionCenters, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const warehousesname = JSON.parse(localStorageService.get("selectedWarehousesname"))?.warehousesname;
    setSelectedWarehousename(warehousesname);
    if(dataOrderRunning.data?.ordersTable?.length >= 0 && dataSectors.data?.sectors?.length >= 0 && dataDistributionCenters.data?.distributionCenters?.length >= 0){
      if(load?.length !== 0){
        getTMSLoad(dataOrderRunning.data?.ordersTable, warehousesname, selectedPageSize, selectedPageId, filter);
      }
    }
    if(fromDate !== localStorage.getItem("range-date-tms-1")){
      setFromDate(localStorage.getItem("range-date-tms-1"))
    }
    if(toDate !== localStorage.getItem("range-date-tms-2")){
      setToDate(localStorage.getItem("range-date-tms-2"))
    }
}, [props.flag,dataOrderRunning, dataSectors, dataDistributionCenters, selectedPageSize, selectedPageId]);



  const getTMSLoad = async (ordersTable, warehousesname, pageSize, pageId, loadId) => {
    setLoading(true);

    const orderConsecutiveShippingList = ordersTable?.filter(order => order.consecutiveShipping).map(order => order.consecutiveShipping);
    
    const endpoint = process.env.REACT_APP_FUNCTION_GET_LOAD_FULL_DATA;

    const bodyLoad = {
      tenantUrl: "navitrans.com.co",
      clientId: "97a8cc5c-65a7-40ac-b1b8-4c9f50e2bc3b",
      clientSecret: "31n8Q~rvYbbRtLqcEhmOob5zhHQPCjQO4611jcZ7",
      tenant: "https://nav-devpa63a793dcc9b33505devaos.axcloud.dynamics.com",
      environment: "DEV",
      loadId: loadId === undefined ? "" : loadId,
      inventLocationId: warehousesname?.length === 0 ? "" : warehousesname,
      pageSize,
      pageId,
      isDescending: true,
      fromDate: fromDate ?? "",
      toDate: toDate ?? "",
      seeDispatched: 0,
      filterByDispatchDate: false,
      transport:""
    };
    
    try {
      await axios.post(endpoint, bodyLoad).then((response) => {
        const _data = response.data.response;
        const count = response.data.count;
        const data = _data
          .map((item) => { 
            let status = "Cargando";

              const lineQty = item.LoadLines?.filter((loadLine) => {
                if (orderConsecutiveShippingList?.includes(loadLine.ShipmentId)) {
                  return true;
                }
                return false;
              }).length;
              
              if(lineQty === item.LoadLines?.length){
                status = "Sincronizado";
              }else if(lineQty > 0){
                status = "Parcialmente Sincronizado";
              }else{
                status = item.LoadStatus;
              }

              let InvoiceQty = 0;
              let SlipShippingQty = 0;
              let LinesQty = 0;
              let ContainersQty = 0;

              let PaymentOnCashValue = 0;
              let PaymentOnCashValueCurrency = "";
              let PaymentMode = "";

              for (let i = 0; i < item.LoadLines.length; i++) {
                const line = item.LoadLines[i];
                InvoiceQty+= line.InvoiceList ? line.InvoiceList.length : 0;

                if(InvoiceQty > 0){
                  for (let j = 0; j < line.InvoiceList.length; j++) {
                    const invoice = line.InvoiceList[j];
                    PaymentMode = invoice.Payment;
                    if(invoice.Payment === "CONTRAENTREGA"){
                      PaymentOnCashValue+= invoice.InvoiceAmount;
                      PaymentOnCashValueCurrency = invoice.CurrencyCode;
                    }
                  }
                }

                SlipShippingQty+= line.PackingSlipList ? line.PackingSlipList.length : 0;
                LinesQty+= line.ItemList ? line.ItemList.length : 0;
                ContainersQty+= line.ContainerList ? line.ContainerList.length : 0;
              }

            return({
            ...item,
            key: item.LoadId,
            Type: item.LoadLines[0]?.Type ?? item.Type,
            LoadStatus: status,
            Order: status === "Enviados" ? 1 : status === "Cargado" ? 2 : status === "Parcialmente Sincronizado" ? 3 : 4,
            InvoiceQty,
            SlipShippingQty,
            LinesQty,
            ContainersQty,
            PaymentOnCashValue,
            PaymentOnCashValueCurrency,
            PaymentMode
          })})
          .sort((a, b) => {
            if (a.LoadShipConfirmUTCDateTime < b.LoadShipConfirmUTCDateTime) {
              return 1;
            }
            if (a.LoadShipConfirmUTCDateTime > b.LoadShipConfirmUTCDateTime) {
              return -1;
            }
            return 0;
          })
          .sort((a, b) => {
            if (a.Order < b.Order) {
              return -1;
            }
            if (a.Order > b.Order) {
              return 1;
            }
            return 0;
          });
        setLoads(data);
        setDataFilterLoad(data);
        setFilter("");
        setLoading(false);
        setCount(count);
      });
    } catch (error) {
      console.error(error);
    }
    if(loadId?.length > 0){
      setFilter(loadId);
    }
  };

  useEffect(() => {
    getDistributionCenters();
    getSectors();
    getOrderRunning();
  }, []);

  useEffect(() => {
    if(dataOrderRunning.data?.ordersTable?.length >= 0 && dataSectors.data?.sectors?.length >= 0 && dataDistributionCenters.data?.distributionCenters?.length >= 0){
      if(load?.length !== 0){
        getTMSLoad(dataOrderRunning.data?.ordersTable, [CenterUser], selectedPageSize, selectedPageId, filter);
      }
    }
  }, [dataOrderRunning, dataSectors, dataDistributionCenters]);
  
  const onChangeText = (event) => {
    const searchText = event?.target?.value;
    if(searchText.length > 0){
      setFilter(searchText);
    }else{
      getTMSLoad(dataOrderRunning.data?.ordersTable, [CenterUser], selectedPageSize, selectedPageId, "")
    }
    
  };

  const columns = [
    {
      key: "LoadShipConfirmUTCDateTime",
      dataIndex: "LoadShipConfirmUTCDateTime",
      title: "Fecha y Hora de Confirmacion de Envio",
      sorter: (a, b) =>
        a.LoadShipConfirmUTCDateTime.localeCompare(b.LoadShipConfirmUTCDateTime),
      render: (value) => {
          if(!value){
            return "N/D";
          }
    
          let date = moment.utc(value);
          let localTime = moment(date).subtract(5, 'hours').format('DD/MM/YYYY h:mm a');
    
          return <span>{localTime === "01/01/1900 12:00 am" || value === "1900-01-01T00:00:00Z" ? "N/D" : localTime}</span>;
      }
    },
    {
      key: "LoadStatus",
      dataIndex: "LoadStatus",
      title: "Estado",
      render: (_, { LoadStatus }) => (
        <Tag
          color={
            LoadStatus === "Sincronizado"
              ? "grey"
              : LoadStatus === "Cargado"
              ? "green"
              : "blue"
          }
          key={LoadStatus}
        >
          {LoadStatus.toUpperCase()}
        </Tag>
      ),
      sorter: (a, b) => a.LoadStatus.localeCompare(b.LoadStatus),
    },
    {
      key: "LoadId",
      dataIndex: "LoadId",
      title: "Carga",
      sorter: (a, b) => a.LoadId.localeCompare(b.LoadId),
    },
    {
      key: "InventLocationId",
      dataIndex: "InventLocationId",
      title: "Bodega",
      sorter: (a, b) => a.InventLocationId.localeCompare(b.InventLocationId),
    },
    {
      key: "OrderNum",
      dataIndex: "OrderNum",
      title: "Orden/TRN",
      sorter: (a, b) =>
        a.OrderNum.localeCompare(b.OrderNum),
        ...getColumnSearchPropsText("Orden/TRN", "OrderNum"),
    },
    {
      key: "CarrierCode",
      dataIndex: "CarrierCode",
      title: "Transportista",
      sorter: (a, b) => a.CarrierCode.localeCompare(b.CarrierCode),
      ...getColumnSearchPropsText("Transportista", "CarrierCode"),
    },
    {
      key: "CarrierServiceCode",
      dataIndex: "CarrierServiceCode",
      title: "Servicio",
      sorter: (a, b) =>
        a.CarrierServiceCode.localeCompare(b.CarrierServiceCode),
    },
    {
      key: "PaymentMode",
      dataIndex: "PaymentMode",
      title: "Forma de Pago",
      sorter: (a, b) =>
        a.PaymentMode.localeCompare(b.PaymentMode),
    },
    {
      key: "PaymentOnCashValue",
      dataIndex: "PaymentOnCashValue",
      title: "Valor a Pagar Contraentrega",
      sorter: (a, b) =>
        a.PaymentOnCashValue - b.PaymentOnCashValue,
    },
    {
      key: "PaymentOnCashValueCurrency",
      dataIndex: "PaymentOnCashValueCurrency",
      title: "Moneda",
      sorter: (a, b) =>
        a.PaymentOnCashValueCurrency.localeCompare(b.PaymentOnCashValueCurrency),
    },
    {
      key: "InvoiceQty",
      dataIndex: "InvoiceQty",
      title: "Facturas",
      sorter: (a, b) =>
      a.InvoiceQty - b.InvoiceQty,
    },
    {
      key: "SlipShippingQty",
      dataIndex: "SlipShippingQty",
      title: "Remisiones",
      sorter: (a, b) =>
        a.SlipShippingQty - b.SlipShippingQty,
    },
    {
      key: "LinesQty",
      dataIndex: "LinesQty",
      title: "Lineas",
      sorter: (a, b) =>
        a.LinesQty - b.LinesQty,
    },
    {
      key: "ContainersQty",
      dataIndex: "ContainersQty",
      title: "Contenedores",
      sorter: (a, b) =>
        a.ContainersQty - b.ContainersQty,
    },
    {
      key: "Type",
      dataIndex: "Type",
      title: "Tipo",
      sorter: (a, b) =>
        a.Type.localeCompare(b.Type),
    }
    ,
  ];

  const handleBack = () => {
    history.replace("/main-upload");
  };

  const handlePageChange = (page, pageSize) => {
      getTMSLoad(dataOrderRunning.data?.ordersTable, [CenterUser], pageSize, page); 
      setLoads();
      setSelectedPageId(page);
      setSelectedPageSize(pageSize);
  };
  
  return (
    <Col className="children-inner-container-with-bg">
      <Button
        style={{ marginTop: 10 }}
        type="primary"
        shape="round"
        ghost={true}
        className="children-inner-button"
        icon={<ArrowLeftOutlined />}
        onClick={handleBack}
      ></Button>
      <Button
        type="primary"
        shape="round"
        ghost={true}
        className="children-inner-button-refresh"
        icon={<RedoOutlined />}
        onClick={()=>{getTMSLoad(dataOrderRunning.data?.ordersTable, [CenterUser], selectedPageSize, selectedPageId); setLoads()}}
      ></Button>
      {selecteLoad ? (
        <Row></Row>
      ) : (
        <Col className="children-inner-container-with-bg">
          <h1 className="titulo">Todas las cargas</h1>
          <Row className="children-inner-row">
            <TextField
              className="custom-filter"
              label="Buscar"
              size={"large"}
              value={filter}
              onChange={(value) => {
                onChangeText(value);
              }}
            />
           <Button
              type="primary"
              shape="round"
              className="SearchOutlinedTMS"
              ghost={true}
              icon={<SearchOutlined />}
              onClick={()=>{getTMSLoad(dataOrderRunning.data?.ordersTable, [CenterUser], selectedPageSize, selectedPageId, filter)}}
            ></Button>
          </Row>
          <br />
          <Row>
            <Table
              className="children-table2"
              loading={loading}
              columns={columns}
              dataSource={load}
              sle
              pagination={false}
              rowKey="key"
              onRow={(record) => {
                return {
                  onClick: () => {
                    setSelecteLoad(record);
                  },
                };
              }}
            />
             <Pagination
              current={selectedPageId}
              pageSize={selectedPageSize}
              total={count}
              onChange={handlePageChange}
              pageSizeOptions={[10,20,50,100,200,500]}
              showSizeChanger={true}
            />
          </Row>
        </Col>
      )}
      {selecteLoad ? (
        <TMSShipmentScreen
          selecteLoad={selecteLoad}
          setSelecteLoad={setSelecteLoad}
          getOrderRunning={getOrderRunning}
          dataOrderRunning={dataOrderRunning}
          setLoads={setLoads}
          getTMSLoad={getTMSLoad}
          dataOrderRunningg={dataOrderRunning.data?.ordersTable}
          CenterUser={CenterUser}
          sectors={dataSectors.data?.sectors}
          distributionCenters={dataDistributionCenters.data?.distributionCenters}
          selectedPageSize={selectedPageSize} 
          selectedPageId = {selectedPageId} 
          filterr = {filter}
        />
      ) : (
        <Row></Row>
      )}
    </Col>
  );
};
