import { gql } from "@apollo/client";

export const fetchRoles = gql`
  query fetchRoles {
    roles {
      id
      name
      label
    }
  }
`;

export const deleteUserWithoutRoute = gql`
  mutation deleteUser($id: uuid) {
    delete_users(where: {id: {_eq: $id}}) {
      returning {
        id
      }
      affected_rows
    }
  }
`;

export const fetchUsers = gql`
query fetchUsers($companyId: uuid!) {
  users(where: {companyId: {_eq: $companyId}}) {
    id
    displayName
    email
    identificationNumber
    phoneNumber
    roleId
    driverLicense
    address
    photoURL
    emailVerified
    enabled
    distributionCenterId
    userDeliveryCenters{
      distributionCenterId,
      distributionCenter{
        name
      }
    }
    role {
      name
      label
    }
    userDistributionCenter {
      name
    }
    routes_aggregate {
    aggregate {
      count
    }
  }
  }
  
}
`;

export const checkIfEmailExists = gql`
  query checkIfEmailExists($email: String!) {
    users(where: { email: { _eq: $email } }) {
      id
    }
  }
`;

export const checkIfIdentificationNumberExists = gql`
  query checkIfIdentificationNumberExists($identificationNumber: String!) {
    users(where: { identificationNumber: { _eq: $identificationNumber } }) {
      id
    }
  }
`;

export const checkIfIdentificationNumberEmailExists = gql`
  query checkIfIdentificationNumberEmailExists(
    $identificationNumber: String!
    $email: String!
  ) {
    users(
      where: {
        identificationNumber: { _eq: $identificationNumber }
        _and: { email: { _neq: $email } }
      }
    ) {
      id
    }
  }
`;

export const fetchDistributionCenters = gql`
  query fetchDistributionCenters($companyId: uuid!) {
    distributionCenters(
      where: { companyId: { _eq: $companyId }, enabled: { _eq: true } }
    ) {
      id
      name
    }
  }
`;

export const insertUser = gql`
mutation insertUser($address: String!, $displayName: String!, $driverLicense: String, $email: String!, $enabled: Boolean!, $identificationNumber: String!, $phoneNumber: String!, $roleId: uuid!, $companyId: uuid!, $photoURL: String, $distributionCenterId: uuid) {
  insert_users(objects: {address: $address, displayName: $displayName, driverLicense: $driverLicense, email: $email, enabled: $enabled, identificationNumber: $identificationNumber, phoneNumber: $phoneNumber, photoURL: $photoURL, roleId: $roleId, companyId: $companyId, distributionCenterId:$distributionCenterId}) {
    affected_rows
    returning {
      id
    }
  }
}
`;

export const updateDistributionCenters = gql`
  mutation insertUserDistributionCenters($distributionCenters: [userDistributionCenters_insert_input!]!) {
    insert_userDistributionCenters(objects: $distributionCenters) {
      affected_rows
      returning{
        id
      }
    }
  }
`;

export const cleanDistrutionCenter = gql`
  mutation deleteUserDistributionCenters($id: uuid!) {
    delete_userDistributionCenters(where: {userId: {_eq: $id}}) {
        affected_rows
    }
  }  
`;

export const updateUser = gql`
  mutation updateUser(
    $id: uuid!
    $address: String!
    $displayName: String!
    $driverLicense: String
    $email: String!
    $enabled: Boolean!
    $identificationNumber: String!
    $phoneNumber: String!
    $roleId: uuid!
    $photoURL: String
  ) {
    update_users(
      _set: {
        address: $address
        displayName: $displayName
        driverLicense: $driverLicense
        email: $email
        enabled: $enabled
        identificationNumber: $identificationNumber
        phoneNumber: $phoneNumber
        photoURL: $photoURL
        roleId: $roleId
      }
      where: { id: { _eq: $id } }
    ) {
      affected_rows
    }
  }
`;

export const updateUserInsertAutomaticPassword = gql`
mutation updateUserInsertAutomaticPassword($id: uuid!, $password: String!) {
  update_users(_set: {password: $password, emailVerified: true}, where: {id: {_eq: $id}}) {
      affected_rows
  }
}
`;
