import { create } from 'zustand'

export const useTMSStore = create((set) => ({
  loads: [],
  count: 0,
  warehousesname: "",
  pageSize: 10,
  pageId: 1,
  fromDate: "",
  toDate: "",
  searchText: "",
  loading: false,
  initLoading: false,
  seeDispatched: 0,
  seeDelivery: 0,
  setLoadsData: ({loads, count}) => set({ loads, count, loading: false, initLoading: false }),
  setWarehousesName: (warehousesname) => set({ warehousesname }),
  startLoading: () => set({ loading: true }),
  startInitLoading: () => set({ initLoading: true }),
  setSearchText: (searchText) => set({ searchText }),
  setSeeDispatched: (seeDispatched) => set({ seeDispatched }),
  setSeeDelivery: (seeDelivery) => set({ seeDelivery })
}))