import React, { useEffect, useState } from 'react';
import { Card, Table } from 'antd';
import { useLazyQuery } from '@apollo/client';

import { fetchUsersVehicles } from '../../queries/homeQueries';
import localStorageService from '../../../../services/localStorageService';

const columns = [
    {
        title: 'Placa',
        dataIndex: 'licencePlate',
        key: 'licencePlate',
        render: (text) => <small>{text}</small>
    },
    {
        title: 'Nombre',
        dataIndex: 'displayName',
        key: 'displayName',
        width: 150,
        render: (text) => <small>{text}</small>
    }
];

export const UsersVehiclesList = (props) => {
    const { usersVehicles, setUsersVehicles, setSelectedUserVehicle, flag} = props;

    const [selectedWarehouse, setSelectedWarehouse] = useState(JSON.parse(localStorageService.get("selectedWarehouses"))?.warehouses);
   
    const [ getUsersVehicles, dataUserVehicles ] = useLazyQuery(fetchUsersVehicles, {variables: {distributionCenterId: selectedWarehouse?.length > 0 ? selectedWarehouse : ["00000000-0000-0000-0000-000000000000"]}, fetchPolicy: 'no-cache'});
    const [currentUserVehicle, setCurrentuserVehicle] = useState();

    useEffect(() => {
        const warehouses = JSON.parse(localStorageService.get("selectedWarehouses"))?.warehouses;
        setSelectedWarehouse(warehouses);
    }, [flag]);

    useEffect(() => {
        getUsersVehicles();
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getUsersVehicles();
        
        if(dataUserVehicles.data && dataUserVehicles.data.userVehicle.length > 0 && props.selectedUserVehicle.length > 0 && props.selectedUserVehicle[0].userId !== currentUserVehicle){
            const currentSelection = dataUserVehicles.data.userVehicle.filter((item) => item.userId===props.selectedUserVehicle[0].userId);
            //setSelectedUserVehicle(currentSelection[0]);
            setCurrentuserVehicle(props.selectedUserVehicle[0].userId);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data, props.selectedUserVehicle]);

    useEffect(() => {
        if(!dataUserVehicles.loading && dataUserVehicles.data && dataUserVehicles.data.userVehicle.length > 0) {
            let records = [];

            // eslint-disable-next-line
            dataUserVehicles?.data?.userVehicle.map((item) => {
                if(item?.assignedRoutes?.length > 0) {
                    return records.push({
                        key: item?.id,
                        licencePlate: item?.vehicle && item?.vehicle?.licencePlate ? item?.vehicle?.licencePlate : 'N/A',
                        displayName: item?.user?.displayName,
                        userId: item?.userId,
                        vehicleId: item?.vehicleId,
                        orderQty: item?.assignedRoutes_aggregate?.aggregate?.count,
                        schedulingStartLocation: item?.schedulingStartLocation
                    });
                }
            });
            setUsersVehicles(records);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataUserVehicles.data, dataUserVehicles.loading]);
    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            if(selectedRowKeys.length > 0) {
                setSelectedUserVehicle(selectedRows);
                setCurrentuserVehicle(selectedRows[0].userId);
            } else {
                setSelectedUserVehicle([]);
                setCurrentuserVehicle('');
            }
        },
        /*getCheckboxProps: record => ({
          disabled: record.name === 'Disabled User', // Column configuration not to be checked
          name: record.name,
        }),*/
    };

    return (
        <section>
            <Table
                    className='children-table-4'
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    sle
                    columns={columns}
                    dataSource={usersVehicles}
                    size="small"
                    pagination={{ pageSize: 23 }}
                />
        </section>
    )
}
