import { gql } from "@apollo/client";

/*export const insertOrdersTable = gql`
    mutation insertOrdersTable($address: String, $addressComplement: String, $custIdentificationNumber: String, $custFullName: String, $custPhoneNumber: String, $paymentMethod: String, $totalOrderAmount: Float, $destination: String, $height: numeric, $width: numeric, $depth: numeric, $weight: numeric, $orderNumber: String, $sectorId: uuid, $custEmail: String, $priority: String, $city: String, $state: String, $notes: String, $distributionCenterId: uuid, $companyId: uuid, $expectedDeliveryStartTime: timestamptz, $expectedDeliveryEndTime: timestamptz, $customerSupplierId: uuid, $addressBookId: uuid, $consecutiveBill: String, $consecutiveRemission: String, $consecutiveTRM: String, $consecutiveSaleOrder: String, $consecutiveShipping: String, $consecutiveBurden: String, $type: String, $cubicMeters: numeric, $orderedQuantity: numeric, $packageId: uuid, $packageContent: String, $destinationDistributionCenterId: uuid, $linesDetail: Boolean, $paymentOrCashOnDeliveryRequired: Boolean, $enableDeliveryWindow: Boolean, $totalCubicMeters: numeric, $enablePackageDimensions: Boolean) {
        insert_ordersTable(objects: {address: $address, addressComplement: $addressComplement, custIdentificationNumber: $custIdentificationNumber, custFullName: $custFullName, custPhoneNumber: $custPhoneNumber, paymentMethod: $paymentMethod, totalOrderAmount: $totalOrderAmount, destination: $destination, height: $height, width: $width, depth: $depth, weight: $weight, orderNumber: $orderNumber, sectorId: $sectorId, custEmail: $custEmail, priority: $priority, city: $city, state: $state, notes: $notes, distributionCenterId: $distributionCenterId, companyId: $companyId, expectedDeliveryStartTime: $expectedDeliveryStartTime, expectedDeliveryEndTime: $expectedDeliveryEndTime, customerSupplierId: $customerSupplierId, consecutiveBill: $consecutiveBill, consecutiveRemission: $consecutiveRemission, consecutiveTRM: $consecutiveTRM, consecutiveSaleOrder: $consecutiveSaleOrder, consecutiveShipping: $consecutiveShipping, consecutiveBurden: $consecutiveBurden, addressBookId: $addressBookId, type: $type, cubicMeters: $cubicMeters, orderedQuantity: $orderedQuantity, packageId: $packageId, packageContent: $packageContent, destinationDistributionCenterId: $destinationDistributionCenterId, linesDetail: $linesDetail, paymentOrCashOnDeliveryRequired: $paymentOrCashOnDeliveryRequired, enableDeliveryWindow: $enableDeliveryWindow, totalCubicMeters: $totalCubicMeters, enablePackageDimensions: $enablePackageDimensions}) {
            returning {
                id
            }
            affected_rows
        }
    }
`;*/

export const insertOrdersTable = gql`
mutation insertOrdersTable($objects: [ordersTable_insert_input!]!) {
    insert_ordersTable(objects: $objects) {
        returning {
            id
            consecutiveBurden
            consecutiveShipping
            orderNumber
        }
        affected_rows
    }
}
`;
export const insertOrdersLine = gql`
mutation insertOrdersLine($objects: [ordersLine_insert_input!]!) {
    insert_ordersLine(objects: $objects) {
      returning {
        id
      }
      affected_rows
    }
  }
  `;
export const fetchOrders = gql`
    query fetchOrders {
        ordersTable(where: {sector: {enabled: {_eq: true}}}, order_by: {createdAt: desc}) {
            id
            orderNumber
            consecutiveSaleOrder
            consecutiveShipping
        }
    }
`;
export const fetchclients = gql`
    query clients($nit: String!) {
        clients (where: {nit: {_eq: $nit}}) {
            id
            nit
            name
            companyId
            email
            accountNumber
            documentType
            currency
            personType
            person
        }
    }
`;
export const fetchSectors = gql`
    query fetchSectors {
        sectors {
            id
            name
            sectorPolygons{
            lat
            lng
            }
        }
    }
`;

export const fetchDistributionCenters = gql`
    query fetchDistributionCenters {
        distributionCenters{
            id
            name
            description
            phone
            email
        }
    }
`;
export const InsertClients = gql`
    mutation insertOrdersLine($objects: [clients_insert_input!]!) {
        insert_clients(objects: $objects) {
        returning {
            id
        }
        affected_rows
        }
    }
`;

export const InsertClientAddresses = gql`
    mutation insertclientAddresses($objects: [clientAddresses_insert_input!]!) {
        insert_clientAddresses(objects: $objects) {
        returning {
            id
        }
        affected_rows
        }
  }
`;
