import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Col,
  Row,
  Skeleton,
  Switch,
  Space,
  Tooltip,
  Spin,
  Input,
} from "antd";
import { useLazyQuery, useMutation } from "@apollo/client";
import {
  DeleteFilled,
  EditFilled,
  MailFilled,
  SearchOutlined,
  PlusOutlined,
  CarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { MainContainer } from "../styles/styles";
import {
  fetchUsers,
  fetchDistributionCenters,
  deleteUserWithoutRoute,
  cleanDistrutionCenter
} from "../../queries/userQueries";
import UserDetailsScreen from "./UserDetailsScreen";
import { useUser } from "../../../../../context/UserContext";
import { IncidentsScreenIndicator } from "./IncidentsScreenIndicator";
import { ConfigurationMoreSettings } from "../../../ConfigurationMoreSettings";

import localStorageService from "../../../../../services/localStorageService";
const companyId = localStorageService.get("companyId");

const handleSearch = (selectedKeys, confirm, dataIndex) => {
  confirm();
};

const handleReset = (clearFilters) => {
  clearFilters();
};

const getColumnSearchPropsText = (title, dataIndex) => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters,
  }) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Buscar ${title}`}
        value={selectedKeys[0]}
        onChange={(e) =>
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }
        onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
        style={{ width: 188, marginBottom: 8, display: "block" }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Buscar
        </Button>
        <Button
          onClick={() => handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Restablecer
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
  ),
  onFilter: (value, record) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : "",
  onFilterDropdownVisibleChange: (visible) => {
    if (visible) {
      /*if(Object.keys(searchText).length > 0) {
                setTimeout(() => searchInput.select(), 100);
            }*/
    }
  },
});

const UsersScreen = () => {
  const { user } = useUser();

  const [fetching, setFetching] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [users, setUsers] = useState([]);
  const [recordToEdit, setRecordToEdit] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [loading, setLoading] = useState(false);
  const [clickedRow, setClickedRow] = useState("");
  const [deleteUserMutation] = useMutation(deleteUserWithoutRoute, {
    fetchPolicy: "no-cache",
  });
  const [cleanDistCenter] = useMutation(cleanDistrutionCenter, {
    fetchPolicy: "no-cache",
  });
  const [getUsers, dataUsers] = useLazyQuery(fetchUsers, {
    fetchPolicy: "no-cache",
  });
  const [getDistributionCenters, dataDistributionCenters] = useLazyQuery(
    fetchDistributionCenters,
    { fetchPolicy: "no-cache" }
  );

  useEffect(() => {
    getUsers({ variables: { companyId } });
    getDistributionCenters({ variables: { companyId } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setFetching(true);
    if (
      !dataUsers.loading &&
      dataUsers.data &&
      dataUsers.data.users.length > 0
    ) {
      dataUsers.data.users.map((item) => {
        let userDistributionCenter = item.userDeliveryCenters.map(distributionCenters => distributionCenters.distributionCenter.map(dname => " "+dname.name));
              
        let distributionCenterId = item.userDeliveryCenters.map((dcenter) => {
          return dcenter.distributionCenterId;
        });
        
        return setUsers((current) => [
          ...current,
          {
            key: item.id,
            id: item.id,
            displayName: item.displayName,
            email: item.email,
            identificationNumber: item.identificationNumber,
            phoneNumber: item.phoneNumber,
            roleId: item.roleId,
            driverLicense: item.driverLicense,
            address: item.address,
            photoURL: item.photoURL,
            enabled: item.enabled,
            roleName: item.role.label,
            emailVerified: item.emailVerified,
            distributionCenterId: distributionCenterId,
            distributionCenter: `${userDistributionCenter}`,
            hasRoutes:
              item.routes_aggregate?.aggregate?.count > 0 ? false : true,
          },
        ]);
        
      });
    }
    setFetching(false);
  }, [dataUsers.data, dataUsers.loading]);

useEffect(()=>{
},[users])

  const handleSendEmailVerification = (data) => {
    setLoading(true);

    const companyBrand = JSON.parse(localStorageService.get("companyDetails"));

    axios({
      method: "post",
      url: process.env.REACT_APP_FUNCTION_EMAIL_VERIFICATION_URL,
      data: {
        id: data.id,
        email: data.email,
        displayName: data.displayName,
        roleId: data.roleId,
        origin: window.location.origin,
        bigBrand: companyBrand.bigBrand,
        companyName: localStorageService.get("company"),
        primaryColor: companyBrand.primaryColor,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          setLoading(false);
          Swal.fire({
            title: "Enviado",
            text: `El correo electrónico de verificación fue enviado.`,
            icon: "success",
            confirmButtonText: "Cerrar",
            timer: 2000,
            timerProgressBar: true,
          });
        } else {
          setLoading(false);
          Swal.fire({
            title: "Error",
            text: `No fue posible enviar el correo electrónico de verificación.`,
            icon: "error",
            confirmButtonText: "Cerrar",
            timer: 2000,
            timerProgressBar: true,
          });
        }
      })
      .catch((error) => {
        console.error("error", error);
        setLoading(false);
        Swal.fire({
          title: "Error",
          text: `No fue posible enviar el correo electrónico de verificación. ${error.toString()}`,
          icon: "error",
          confirmButtonText: "Cerrar",
          timer: 2000,
          timerProgressBar: true,
        });
      });
  };

  const handleDelete = (record) => {
    Swal.fire({
      title: `¿Desea eliminar el usuario "${record.displayName}"?`,
      text: "¡Este proceso es irreversible!",
      showDenyButton: true,
      confirmButtonText: "Eliminar",
      denyButtonText: "Cancelar",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const deleteUserWithDistributionCenters = await cleanDistCenter({
            variables: { id: record.id },
            fetchPolicy: "no-cache",
        });
        const res = await deleteUserMutation({
          variables: { id: record.id },
          fetchPolicy: "no-cache",
        });
        if (res.data.delete_users.affected_rows > 0 && deleteUserWithDistributionCenters.data.delete_userDistributionCenters.affected_rows > 0) {
          setUsers(users.filter((item) => item.id !== record.id));
          Swal.fire({
            title: "Eliminado",
            text: `El usuario "${record.displayName}" fue eliminado.`,
            icon: "success",
            confirmButtonText: "Cerrar",
          });
        }
      }
    });
  };

  const columns = [
    {
      key: "displayName",
      dataIndex: "displayName",
      title: "Nombre y apellido",
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
      ...getColumnSearchPropsText("Nombre", "displayName"),
    },
    {
      key: "email",
      dataIndex: "email",
      title: "Correo electrónico",
      sorter: (a, b) => a.email.localeCompare(b.email),
      ...getColumnSearchPropsText("Email", "email"),
    },
    {
      key: "identificationNumber",
      dataIndex: "identificationNumber",
      title: "Nro. Identificación",
      sorter: (a, b) =>
        a.identificationNumber.localeCompare(b.identificationNumber),
      ...getColumnSearchPropsText("Identificación", "identificationNumber"),
    },
    {
      key: "phoneNumber",
      dataIndex: "phoneNumber",
      title: "Nro. Celular",
      sorter: (a, b) => a.phoneNumber.localeCompare(b.phoneNumber),
      ...getColumnSearchPropsText("Celular", "phoneNumber"),
    },
    {
      key: "roleName",
      dataIndex: "roleName",
      title: "Rol",
      sorter: (a, b) => a.roleName.localeCompare(b.roleName),
      ...getColumnSearchPropsText("Rol", "roleName"),
    },
    {
      key: "distributionCenter",
      dataIndex: "distributionCenter",
      title: "Centros de distribución",
      sorter: (a, b) =>
        a.distributionCenter.localeCompare(b.distributionCenter),
      ...getColumnSearchPropsText("Rol", "distributionCenter"),
    },
    /*{
            key: 'driverLicense',
            dataIndex: 'driverLicense',
            title: 'Nro. Licencia'
        },*/
    {
      key: "emailVerified",
      dataIndex: "emailVerified",
      title: "Verificado",
      align: "center",
      sorter: {
        compare: (a, b) => a.emailVerified - b.emailVerified,
      },
      render: (value) => {
        return <Switch checked={value} disabled />;
      },
    },
    {
      key: "enabled",
      dataIndex: "enabled",
      title: "Habilitado",
      align: "center",
      sorter: {
        compare: (a, b) => a.enabled - b.enabled,
      },
      render: (value) => {
        return <Switch checked={value} disabled />;
      },
    },
    {
      key: "actions",
      dataIndex: "actions",
      title: "Acciones",
      align: "center",
      render: (_, record) => {
        return (
          <Space>
            <Tooltip title="Modificar">
              <Button
                type="primary"
                shape="round"
                icon={<EditFilled />}
                onClick={() => {
                  handleEdit(record);
                }}
              />
            </Tooltip>
            <Tooltip title="Enviar email de verificación">
              <Button
                disabled={record.emailVerified}
                type="primary"
                shape="round"
                icon={<MailFilled />}
                onClick={() => {
                  handleSendEmailVerification(record);
                }}
              />
            </Tooltip>
            <Tooltip title="Eliminar usuario">
              <Button
                disabled={!record.hasRoutes}
                type="primary"
                shape="round"
                icon={<DeleteFilled />}
                onClick={() => {
                  handleDelete(record);
                }}
              />
            </Tooltip>
          </Space>
        );
      },
    },
  ];

  const handleNew = () => {
    setIsEditing(false);
    setShowDrawer(true);
  };

  const handleEdit = (record) => {
    setIsEditing(true);
    setRecordToEdit(record);
    setShowDrawer(true);
  };

  if (loading) {
    return (
      <center
        style={{
          height: window.innerHeight,
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <Spin size="large" />
      </center>
    );
  }

  return (
    <Col className="children-inner-container-with-bg">
      <ConfigurationMoreSettings />
      <Row className="children-inner-row">
        <Col span={5} className="children-inner-col">
          <IncidentsScreenIndicator value={users.length} label={"Usuarios"}>
            <CarOutlined
              style={{ backgroundColor: "var(--primaryColor)" }}
              className="report-indicator-icon"
            />
          </IncidentsScreenIndicator>
        </Col>
        <Col span={5} className="children-inner-col">
          <IncidentsScreenIndicator
            value={users.filter((item) => item.enabled).length}
            label={"Activos"}
          >
            <CheckOutlined
              style={{ backgroundColor: "#9ccc65" }}
              className="report-indicator-icon"
            />
          </IncidentsScreenIndicator>
        </Col>
        <Col span={5} className="children-inner-col">
          <IncidentsScreenIndicator
            value={users.filter((item) => !item.enabled).length}
            label={"Inactivos"}
          >
            <ClockCircleOutlined
              style={{ backgroundColor: "#ffba57" }}
              className="report-indicator-icon"
            />
          </IncidentsScreenIndicator>
        </Col>
        <Col span={3} offset={6} className="children-inner-col">
          <Button
            type="primary"
            size={"large"}
            className="children-inner-button"
            icon={<PlusOutlined />}
            onClick={handleNew}
          >
            Nuevo usuario
          </Button>
        </Col>
      </Row>
      <Row>
        <Skeleton loading={fetching} active>
          <Table
            className="children-table"
            loading={fetching}
            columns={columns}
            dataSource={users}
            pagination={{ pageSize: 10 }}
            rowKey="key"
            onRow={(record) => {
              return {
                onClick: () => {
                  setClickedRow(record.id);
                },
              };
            }}
            rowClassName={(record) => [
              "mouse-row-enter",
              record.id === clickedRow ? "table-row-color" : "",
            ]}
          />
        </Skeleton>
        <UserDetailsScreen
          props={{
            showDrawer,
            setShowDrawer,
            recordToEdit,
            setRecordToEdit,
            isEditing,
            setIsEditing,
            users,
            setUsers,
            dataDistributionCenters,
          }}
        />
      </Row>
    </Col>
  );
};

export default UsersScreen;
